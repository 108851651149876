import React, { useEffect, useRef, useState } from "react";
import { Navbar, Image, Container, Modal, Button, Row, Col, FormGroup, FormLabel, FormControl, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from '../assets/images/CRM/fablead_crm.png';
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_URL } from "../utils";
function Cmsheader() {
  const [successMessage, setSuccessMessage] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState("");
  const [isVerified, setVerified] = useState(false);
  const recaptchaRef = useRef(null);
  const [isFormReady, setFormReady] = useState(false);
  useEffect(() => {
    const currentDomain = window.location.origin;
    const dynamicLogoUrl = `${currentDomain}/crm`;
    setLogoUrl(dynamicLogoUrl);
  }, [location.pathname]);
 

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    setVerified(false);
    setFormReady(false);

    console.log("Form Data:", formData);

    try {
      // ${logoUrl}
      const response = await fetch(`${BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "hXuRUGsEGuhGf6KGeereSSas",
        },
        body: JSON.stringify(formData),
      });

      if (response) {
        const data = await response.json();
        if (data.status === "success") {
          setSuccessMessage("Message sent successfully!");

          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });

          e.target.reset();

          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);

        } else {
          console.error("Error sending message");

        }
      } else {
        console.error("Error sending message. Server returned status:", response.status);

      }
    } catch (error) {
      console.error("Error sending message:", error.message);

    }
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const isNavLinkActive = (to) => {
    return location.pathname === to;
  };

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleVerification = (value) => {
    setVerified(value);
    setFormReady(value);
  };
  return (
    <>
      <header id="header" className="fixed-top1 py-2">
        <Container className="d-flex align-items-center">
          <div className="logo me-auto">
            <Link to={logoUrl}>
              <Image src={logo} className="img-fluid" alt="Fablead Developers Technolab" />
            </Link>
          </div>

          <Navbar id="navbar" className={`navbar ${mobileMenuOpen ? "navbar-mobile" : ""}`}>
            <ul>
              <li>
                <div className="text-center">
                  <button className="btn-learn-more-crm" onClick={handleShowModal}>
                    Get started
                  </button>
                </div>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle-crm" onClick={toggleMobileMenu}></i>
          </Navbar>
        </Container>
      </header>

      {/* Modal component */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
        <Modal.Title className="text-center">
                      
                      <Image src={logo} className="img-fluid-crm-form" alt="Fablead Developers Technolab" />
                  
                        <h5 className="mt-2">Get started with your
                        15-day free trial</h5>
                     
                    
                  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form
                onSubmit={handleSubmit}
                role="form"
                className="php-email-form"
              >
              <Row>
                <Col lg={6}>
                    <FormGroup >
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <FormControl
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                    </Col>
                    <Col lg={6}>
                    <FormGroup>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <FormControl
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                    </Col>
                    </Row>

                <FormGroup className="mt-2">
                  <FormLabel htmlFor="subject">Subject</FormLabel>
                  <Form.Control
                    type="text"
                    name="subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mt-2">
                  <FormLabel htmlFor="message">Message</FormLabel>
                  <FormControl
                    as="textarea"
                    name="message"
                    rows="5"
                   id="message"
                   value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
         
                <FormGroup className="mt-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LeARuMUAAAAAE1lFiqVl4FXq8bWKV-xrgRB5y-D"
                      onChange={handleVerification}
                    />
                  </FormGroup>
              </Form>
        </Modal.Body>
        <Modal.Footer>
        
          <button type="submit" className="send-message-btn-getstarted-form-crm" >
            Submit
          </button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default React.memo(Cmsheader);
