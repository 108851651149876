import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import ReactNodeAngular from '../assets/images/client_logo/react-node-angular-js-min.webp';
import Codeigniter from '../assets/images/client_logo/651_codeigniter-min.webp';
import Flutter from '../assets/images/client_logo/flutter-min.webp'
import Laravel from '../assets/images/client_logo/laravel_new-min.webp';
import Wordpress from '../assets/images/client_logo/wordpress-min.webp';
import Php from '../assets/images/client_logo/php-fablead-1-min.webp';
import Squarespace from '../assets/images/client_logo/sqarespace-min.webp';
import Python from '../assets/images/client_logo/Python (1)-min.webp';
function Clients() {
  return (
    <section id="clients" className="clients section-bg1">
      <Container>
        <div className="section-title">
          <div>Our Technologies</div>
        </div>

        <Row data-aos="zoom-in ">

          <Col lg={3} md={6} className="mb-3 d-flex align-items-center justify-content-center">
            <div className="technology_img pt-1">
              <Image src={Php} className="img-fluid" alt="php" />
            </div>
          </Col>

          <Col lg={3} md={6} className="mb-3  d-flex align-items-center justify-content-center">
            <div className="technology_img pt-1">            
              <Image src={Wordpress} className="img-fluid" alt="wordpress" />
            </div>
          </Col>
          <Col lg={3} md={6} className="mb-3  d-flex align-items-center justify-content-center">
            <div className="technology_img pt-3">            
            <Image src={Laravel} className="img-fluid" alt="laravel" />
            </div>
          </Col>
          <Col lg={3} md={6} className="mb-3 d-flex align-items-center justify-content-center">
            <div className="technology_img pt-2">
              <Image src={Codeigniter} className="img-fluid" alt="codeigniter" />
            </div>
          </Col>
        
          <Col lg={3} md={6} className="mb-3 d-flex align-items-center justify-content-center">
            <div className="technology_img pt-3">
              <Image src={Squarespace} className="img-fluid" alt="sqauarespace" />
            </div>
          </Col>
          <Col lg={3} md={6} className="mb-3 d-flex align-items-center justify-content-center">
            <div className="technology_img pt-3">
              <Image src={Flutter} className="img-fluid" alt="flutter" />
            </div>
          </Col>
          <Col lg={3} md={6} className="d-flex align-items-center justify-content-center">
            <div className="technology_img pt-3">
              <Image src={ReactNodeAngular} className="img-fluid" alt="node/react/angular" />
            </div>
          </Col>
          <Col lg={3} md={6} className="d-flex align-items-center justify-content-center">
            <div className="technology_img">
              <Image src={Python} className="img-fluid" alt="python" />
            </div>
          </Col>
        </Row>

      </Container>
    </section>
  );
}

export default React.memo(Clients)
