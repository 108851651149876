import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Row, Col, Form, FormGroup, FormLabel, FormControl, Button, Container } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useLocation } from "react-router-dom";
import { BASE_URL } from "../utils";

function Contact() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormReady, setFormReady] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const recaptchaRef = useRef(null);

  const handleVerification = (value) => {
    setVerified(value);
    setFormReady(value);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    setVerified(false);
    setFormReady(false);

    console.log("Form Data:", formData);

    try {
      // ${logoUrl}
      const response = await fetch(`${BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "hXuRUGsEGuhGf6KGeereSSas",
        },
        body: JSON.stringify(formData),
      });

      if (response) {
        const data = await response.json();
        if (data.status === "success") {
          setSuccessMessage("Message sent successfully!");

          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });

          e.target.reset();

          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);

        } else {
          console.error("Error sending message");

        }
      } else {
        console.error("Error sending message. Server returned status:", response.status);

      }
    } catch (error) {
      console.error("Error sending message:", error.message);

    }
  };

  return (
    <section>
      <div id="contact" className="contact">
        <Container data-aos="fade-up">
          <div className="section-title">
            <div>Contact Us</div>
          </div>
          <Row>
            <Col lg={5} className="d-flex align-items-stretch ">
              <div className="info section-bg ">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>A-5001, Ascon Plaza,
                    Adajan, Surat,
                    <br />
                    Gujarat 395009 – India</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p> <Link to="mailto:info@fableadtechnolabs.com" ><label>info@fableadtechnolabs.com</label></Link></p>
                </div>
                <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.978800847412!2d72.7962328743919!3d21.193001082118858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f0bbb40f751%3A0xbacdb736bad4987!2sFablead%20Developers%20Technolab!5e0!3m2!1sen!2sin!4v1700121136280!5m2!1sen!2sin"
                  frameBorder="0"
                  allowFullScreen
                  className="google_map"
                  loading="lazy"
                >
                </iframe>
              </div>
            </Col>
            <Col lg={7} className="mt-5 mt-lg-0 d-flex align-items-stretch">
              <Form
                onSubmit={handleSubmit}
                role="form"
                className="php-email-form"
              >
                <Row className="mb-2">
                  {/* {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} */}
                  {successMessage && <div className="alert alert-success">{successMessage}</div>}
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel htmlFor="name">Your Name</FormLabel>
                      <FormControl
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel htmlFor="email">Your Email</FormLabel>
                      <FormControl
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-2">
                  <FormLabel htmlFor="subject">Subject</FormLabel>
                  <Form.Control
                    type="text"
                    name="subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="message">Message</FormLabel>
                  <FormControl
                    as="textarea"
                    name="message"
                    rows="10"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <Row className="re-captcha">
                  <Col lg={6} >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LeARuMUAAAAAE1lFiqVl4FXq8bWKV-xrgRB5y-D"
                      onChange={handleVerification}
                    />
                  </Col>
                  <Col lg={6} className="mt-3 ">
                    <button type="submit" className="send-message-btn" disabled={!isFormReady}>
                      Send Message
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default React.memo(Contact)
