import React, { useEffect, useRef, useState } from "react";
import { Image, Carousel, Modal, Row, Col, Form, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import banner1 from '../../assets/images/Hero_Banner/mobile-app.webp'
import banner2 from '../../assets/images/Hero_Banner/web design (3)-min.webp'
import banner3 from '../../assets/images/Hero_Banner/web-development.webp'
import banner4 from '../../assets/images/Career/career.webp'
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../../assets/images/CRM/fablead_crm.png';
import { BASE_URL } from "../../utils";
import { Link as ScrollLink } from "react-scroll"; // Import the ScrollLink
function Home() {
  const [successMessage, setSuccessMessage] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState("");
  const [isVerified, setVerified] = useState(false);
  const recaptchaRef = useRef(null);
  const [isFormReady, setFormReady] = useState(false);
  useEffect(() => {
    const currentDomain = window.location.origin;
    const dynamicLogoUrl = `${currentDomain}/crm`;
    setLogoUrl(dynamicLogoUrl);
  }, [location.pathname]);
 

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    setVerified(false);
    setFormReady(false);

    console.log("Form Data:", formData);

    try {
      // ${logoUrl}
      const response = await fetch(`${BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "hXuRUGsEGuhGf6KGeereSSas",
        },
        body: JSON.stringify(formData),
      });

      if (response) {
        const data = await response.json();
        if (data.status === "success") {
          setSuccessMessage("Message sent successfully!");

          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });

          e.target.reset();

          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);

        } else {
          console.error("Error sending message");

        }
      } else {
        console.error("Error sending message. Server returned status:", response.status);

      }
    } catch (error) {
      console.error("Error sending message:", error.message);

    }
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const isNavLinkActive = (to) => {
    return location.pathname === to;
  };

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleVerification = (value) => {
    setVerified(value);
    setFormReady(value);
  };
  return (
    <div id="herocrm" className="align-items-center">
        <div className="container">
        <div className="row">
          
    <div className="col-lg-6">
    <div className="crm-home-pages">
      <h1 className="mb-4">Customer Relationship Management</h1>
      <h3>We specialize in responsive, scalable, and future-ready solutions for Web.</h3>
      <h3>Our Company Include Web Development , Web Designing, Mobile App Development .</h3>
      <div className="d-flex justify-content-start">
      <ScrollLink
                  to="herocrmfeatures"  // Scroll to the Features section with ID herocrmfeatures
                  smooth={true}  // Enable smooth scrolling
                  duration={500} // Duration of scrolling in milliseconds
                  className="btn-learn-more-crm me-4"
                >
               Features   </ScrollLink>
        <div className="text-center">
                  <button className="btn-learn-more-crm me-4" onClick={handleShowModal}>
                    Get started
                  </button>
                </div>
      </div>
    </div>
    </div>
    <div className="col-lg-6">
    <Image className="d-block w-100" src={banner4} alt="ecommerce" />
    </div>
    </div>
    </div>
        {/* Modal component */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
        <Modal.Title className="text-center">
                      
                      <Image src={logo} className="img-fluid-crm-form" alt="Fablead Developers Technolab" />
                  
                        <h5 className="mt-2">Get started with your
                        15-day free trial</h5>
                     
                    
                  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form
                onSubmit={handleSubmit}
                role="form"
                className="php-email-form"
              >
              <Row>
                <Col lg={6}>
                    <FormGroup >
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <FormControl
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                    </Col>
                    <Col lg={6}>
                    <FormGroup>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <FormControl
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                    </Col>
                    </Row>

                <FormGroup className="mt-2">
                  <FormLabel htmlFor="subject">Subject</FormLabel>
                  <Form.Control
                    type="text"
                    name="subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mt-2">
                  <FormLabel htmlFor="message">Message</FormLabel>
                  <FormControl
                    as="textarea"
                    name="message"
                    rows="5"
                   id="message"
                   value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
         
                <FormGroup className="mt-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LeARuMUAAAAAE1lFiqVl4FXq8bWKV-xrgRB5y-D"
                      onChange={handleVerification}
                    />
                  </FormGroup>
              </Form>
        </Modal.Body>
        <Modal.Footer>
        
          <button type="submit" className="btn-learn-more-crm" >
            Submit
          </button>
          
        </Modal.Footer>
      </Modal>
  </div>
  
  );
}
export default React.memo(Home)
