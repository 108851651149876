import React from "react";
import { Image, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import banner1 from '../assets/images/Hero_Banner/mobile-app.webp'
import banner2 from '../assets/images/Hero_Banner/web design (3)-min.webp'
import banner3 from '../assets/images/Hero_Banner/web-development.webp'
import banner4 from '../assets/images/Hero_Banner/ecommerce.webp'
function Hero() {
  return (
    <div id="hero" className=" align-items-center">
      <Carousel>
        <Carousel.Item>
          <Image className="d-block w-100" src={banner4} alt="ecommerce" />
          <Carousel.Caption className="">
            <h1>Make Your Online Presence with Website development services</h1>
            <h3 >We specialize in responsive, scalable, and future-ready solutions for Web.</h3>
            <div className="d-flex justify-content-start ">
              <Link to="/about" className="btn-get-started scrollto">GET STARTED</Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image className="d-block w-100" src={banner2} alt="web design" />
          <Carousel.Caption className="">
            <h1>Seamless Fusion of Design Excellence and Technical Prowess</h1>
            <h3>Our designs prioritize user experience, ensuring your audience stays engaged and converts.</h3>
            <div className="d-flex justify-content-start ">
              <Link to="/about" className="btn-get-started scrollto">GET STARTED</Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image className="d-block w-100" src={banner3} alt="web-development" />
          <Carousel.Caption className="">
            <h1>CMS based Website development and designing</h1>
            <h3 >Popular CMS options include WordPress, Joomla, Squarespace, Wix and many others.</h3>
            <div className="d-flex justify-content-start ">
              <Link to="/about" className="btn-get-started scrollto">GET STARTED</Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image className="d-block w-100" src={banner1} alt="mobile-app" />
          <Carousel.Caption className="">
            <h1>Developing robust applications for both iOS and Android platforms.</h1>
            <h3 >We prioritize user experience to create apps that users love and engage with effortlessly</h3>
            <div className="d-flex justify-content-start ">
              <Link to="/about" className="btn-get-started scrollto">GET STARTED</Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
export default React.memo(Hero)
