import React, { useEffect, useState } from "react";
import { Row, Col, Image, Container } from 'react-bootstrap';
import SkillsImage1 from '../assets/images/service-banners/Group 106-min.webp'

function Skills() {

  const [htmlPercentage, setHtmlPercentage] = useState(0); 
  const [cssPercentage, setCssPercentage] = useState(0);
  const [Wordpress, setWordpress] = useState(0);
  const [photoshopPercentage, setPhotoshopPercentage] = useState(0);
  const [pythonPercentage, setPythonPercentage] = useState(0);
  const [reactPercentage, setReactPercentage] = useState(0);
  const [nodejsPercentage, setNodejsPercentage] = useState(0);
  const [mongodbPercentage, setMongodbPercentage] = useState(0);


  const startAnimation = () => {
    setHtmlPercentage(95);
    setCssPercentage(95);
    setWordpress(95);
    setPhotoshopPercentage(90);
    setPythonPercentage(90);
    setReactPercentage(95);
    setNodejsPercentage(90);
    setMongodbPercentage(90);
  };

  useEffect(() => {

    const animationTimeout = setTimeout(startAnimation, 2000);


    return () => clearTimeout(animationTimeout);
  }, []);

  return (
    <section id="skills" className="skills">
      <Container className="" data-aos="fade-up">
      <div className="section-title">
          <div>What we Provide</div>
        </div>
        <Row>
          <Col lg={6} className="d-flex align-items-center" data-aos="fade-right" data-aos-delay={100}>
            <Image src={SkillsImage1} className="skils-img-fluid" alt="Skills" />
          </Col>
          <Col lg={6} className="pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay={100}>
         
            <div className="skills-content">
              <div className="progress">
                <span className="skill">PHP <i className="val">{htmlPercentage}%</i></span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated htmlPercentages"
                    role="progressbar"
                    aria-valuenow={htmlPercentage}
                    aria-valuemin="95"
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
              <div className="progress ">
                <span className="skill">Wordpress & WooCommerce <i className="val">{Wordpress}%</i></span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated Wordpress"
                    role="progressbar"
                    aria-valuenow={Wordpress}
                    aria-valuemin="95"
                    aria-valuemax={100}
                    
                  ></div>
                </div>
              </div>
              <div className="progress ">
                <span className="skill">Laravel <i className="val">{cssPercentage}%</i></span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated cssPercentage"
                    role="progressbar"
                    aria-valuenow={cssPercentage}
                    aria-valuemin="95"
                    aria-valuemax={100}
                    
                  ></div>
                </div>
              </div>

              <div className="progress ">
        <span className="skill">Codeignitor <i className="val">{pythonPercentage}%</i></span>
        <div className="progress-bar-wrap">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated pythonPercentage"
            role="progressbar"
            aria-valuenow={pythonPercentage}
            aria-valuemin="0"
            aria-valuemax={100}
          ></div>
        </div>
      </div>
      <div className="progress ">
        <span className="skill">Squarespace <i className="val">{mongodbPercentage}%</i></span>
        <div className="progress-bar-wrap">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated mongodbPercentage"
            role="progressbar"
            aria-valuenow={mongodbPercentage}
            aria-valuemin="0"
            aria-valuemax={100}
          ></div>
        </div>
      </div>

              <div className="progress ">
                <span className="skill">Flutter <i className="val">{photoshopPercentage}%</i></span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated photoshopPercentage"
                    role="progressbar"
                    aria-valuenow={photoshopPercentage}
                    aria-valuemin="0"
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
          

      <div className="progress ">
        <span className="skill">Node/React <i className="val">{reactPercentage}%</i></span>
        <div className="progress-bar-wrap">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated reactPercentage"
            role="progressbar"
            aria-valuenow={reactPercentage}
            aria-valuemin="0"
            aria-valuemax={100}
          ></div>
        </div>
      </div>
      <div className="progress ">
        <span className="skill">Python <i className="val">{nodejsPercentage}%</i></span>
        <div className="progress-bar-wrap">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated nodejsPercentage"
            role="progressbar"
            aria-valuenow={nodejsPercentage}
            aria-valuemin="0"
            aria-valuemax={100}
          ></div>
        </div>
      </div>
      <div className="progress ">
        <span className="skill">HTML / CSS / CSS3 / Bootstrap <i className="val">{nodejsPercentage}%</i></span>
        <div className="progress-bar-wrap">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated nodejsPercentage"
            role="progressbar"
            aria-valuenow={nodejsPercentage}
            aria-valuemin="0"
            aria-valuemax={100}
          ></div>
        </div>
      </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default React.memo(Skills)
