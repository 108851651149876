import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Log() {
  return (
    <section id="cta" className="cta">
      <Container className="" data-aos="zoom-in">
        <Row className="">
          <Col lg={9} className="text-center text-lg-start">
            <h3 className="cta-heading">Grow Your Business With Us.</h3>
            <p>Don't To Miss Our Special Offers! Flexible and cost-effective solutions.</p>
          </Col>
          <Col lg={3} className="col-lg-3 cta-btn-container text-center">
            <Link className="cta-btn align-middle" to="/contact">Contact Us</Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default React.memo(Log)
