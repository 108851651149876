import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from 'react-bootstrap';

function Testimonial() {
  const testimonialData = [
    {
      name: 'GABRIEL O. - Manchester, GB',
      testimonial: 'Company’s work is timely and of the highest standard. Company’s professionalism is exemplary and his patience with clients is excellent. It is very clear that they understands his chosen field very well. I would definitely recommend their services to others. ',
    },
    {
      name: 'STEFANO S. - Zurich, CH',
      testimonial: 'I strongly recommend this company because they did a great job with my devastated old Wordpress site. they showed analytics skill, speed, deep knowledge of Wordpress and php.Good cost per hour in view of a great job quality.Fast to reply, he has got patience and calmness! Absolutely positive!',
    },
    {
      name: 'SACHIN K. - Lowell, MA, US',
      testimonial: 'Appreciate their quality work and speed in which they completed the project. It was good working with them and will definitely contact them for any future work ..',
    },
    
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials" className="testimonials section-bg">
      <Container className="" data-aos="fade-up">
        <div className="section-title">
        <i className="bi bi-quote testimonial_icon"></i>
          <div>Testimonials</div>
        </div>
        <Slider {...sliderSettings} className='text-center'>
          {testimonialData.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <p>{testimonial.testimonial}</p>
              <h3 className='name'>{testimonial.name}</h3>

            </div>
            
          ))}
        </Slider>
      </Container>
    </section>
  );
}

export default React.memo(Testimonial)
