import React, { useEffect } from "react";
import { Navbar, Image, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from '../assets/images/service-banners/250x150 (1).webp';
import { useState } from "react";


function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState("");
  useEffect(() => {
    const currentDomain = window.location.origin;

    const dynamicLogoUrl = `${currentDomain}/`;

    setLogoUrl(dynamicLogoUrl);
  }, [location.pathname]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const isNavLinkActive = (to) => {
    return location.pathname === to;
  };

 const toggleDropdown = () => {
    setDropdownActive(!dropdownActive); // Toggle dropdown state
  };
  return (
    <header id="header" className="fixed-top">

      <Container className=" d-flex align-items-center">
        <div className="logo me-auto">

          <Link to={logoUrl}> <Image src={logo} className="img-fluid" alt="Fablead Developers Technolab" /></Link>
        </div>

        <Navbar id="navbar" className={`navbar ${mobileMenuOpen ? "navbar-mobile" : ""}`}>
          <ul>
            <li>
              <Link to="/" rel="noopener noreferrer" className={`nav-link scrollto ${isNavLinkActive("/") ? "active" : ""}`}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/crm" rel="noopener noreferrer" className={`nav-link scrollto d-none ${isNavLinkActive("/crm") ? "active" : ""}`}>
           
              </Link>
            </li>
            <li>
              <Link to="/about" rel="noopener noreferrer" className={`nav-link scrollto ${isNavLinkActive("/about") ? "active" : ""}`}>
                About
              </Link>
            </li>
            <li>
              <Link to="/services" rel="noopener noreferrer" className={`nav-link scrollto ${isNavLinkActive("/services") || isNavLinkActive("/services/website-development") || isNavLinkActive("/services/website-design") || isNavLinkActive("/services/mobile-apps") || isNavLinkActive("/services/e-commerce") || isNavLinkActive("/services/cms-website") || isNavLinkActive("/services/web-maintenance") ? "active" : ""}`}>
                Services
              </Link>
            </li>

        {/* Start Hide serices Detail pages */}
            <li className="d-none">
              <Link to="/services/cms-website">
              CMS-Website
              </Link>
            </li>
            <li className="d-none">
              <Link to="/services/web-maintenance">
              web-maintenance
              </Link>
            </li>
            <li className="d-none">
              <Link to="/services/website-design">
              website-design
              </Link>
            </li>
            <li className="d-none">
              <Link to="/services/website-development">
              Website development
              </Link>
            </li>
            <li className="d-none">
              <Link to="/services/mobile-apps">
              Mobile-apps
              </Link>
            </li>
            <li className="d-none">
              <Link to="/services/e-commerce">
              E-commerce
              </Link>
            </li>
            <li className="d-none">
              <Link to="/404">
              404
              </Link>
            </li>
           
            {/* END Hide serices Detail pages */}
            <li>
              <Link to="/portfolio" rel="noopener noreferrer" className={`nav-link scrollto ${isNavLinkActive("/portfolio") ? "active" : ""}`}>
                Portfolio
              </Link>
            </li>
            <li className="dropdown" onClick={toggleDropdown}>
              <Link to="/training-and-courses" className={`${isNavLinkActive("/training-and-courses") ? "active" : ""}`}>
                <span className="nav-link scrollto">Training & Courses</span>
                <i className="bi bi-chevron-down pt-1"></i>
              </Link>
              <ul className={dropdownActive ? "dropdown-active" : ""}>
                <li><Link to="/training-and-courses/front-end-development" className="product-dropdown">Frontend Development</Link></li>
                <li><Link to="/training-and-courses/back-end-development" className="product-dropdown">Backend Development</Link></li>
                <li><Link to="/training-and-courses/full-stack-development" className="product-dropdown">Fullstack Development</Link></li>
                <li><Link to="/training-and-courses/cms-development" className="product-dropdown">Wordpress CMS Development</Link></li>
                <li><Link to="/training-and-courses/mobile-app-development" className="product-dropdown">Mobile App Development</Link></li>
              </ul>
            </li>
            <li>
              <Link to="/careers" rel="noopener noreferrer" className={`nav-link scrollto ${isNavLinkActive("/careers") ? "active" : ""}`}>
                Careers
              </Link>
            </li>
            <li>
              <Link to="/contact" className={`nav-link scrollto ${isNavLinkActive("/contact") ? "active" : ""}`}>
                Contact
              </Link>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileMenu}></i>
        </Navbar>
      </Container>
    </header>
  );
}

export default React.memo(Header)
