import { faCartArrowDown, faDesktop, faMobileScreenButton, faTv, faDiamond, faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section id="services" className="services section-bg1">
      <Container className="" data-aos="fade-up">
        <div className="section-title">
          <div>Services</div>
        </div>

        <Row className="mt-4">
          <Col xl={4} md={6} className="mb-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
            <div className="icon-box">
              <div className="icon text-center  w-100"><div className="mainicon-div"><i> <FontAwesomeIcon icon={faTv} /> </i></div></div>
              <h4 className="text-center"><Link to="/services/website-development">Website Development</Link></h4>
              <p className="text-center">We provides High Quality Website Development & Website Designing for your Business.</p>
              <div className="text-center">
                <Link to="/services/website-development" className="btn-learn-more mt-4">
                  <button >
                    Read More
                  </button></Link>
              </div>
            </div>
          </Col>
          <Col xl={4} md={6} className="mb-3 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay={200}>
            <div className="icon-box">
              <div className="icon text-center w-100"><div className="mainicon-div"><i><FontAwesomeIcon icon={faDesktop} /></i></div></div>
              <h4 className="text-center"><Link to="/services/website-design">Website Designing</Link></h4>
              <p className="text-center">We have a Dynamic and Talented team of Professional Designers, illustrators, animators, and programmers.</p>
              {/* <a href="servicespage" className="btn-learn-more mt-4">Read More</a> */}

              <div className="text-center">
                <Link to="/services/website-design" className="btn-learn-more mt-4">
                  <button >
                    Read More
                  </button></Link>
              </div>
            </div>

          </Col>

          <Col xl={4} md={6} className="mb-3 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay={300}>
            <div className="icon-box">
              <div className="icon text-center  w-100"><div className="mainicon-div"><i ><FontAwesomeIcon icon={faMobileScreenButton} /></i></div></div>
              <h4 className="text-center"><Link to="/services/mobile-apps">Mobile App</Link></h4>
              <p className="text-center">We are a mobile app development company , specializes in creating android & ios apps.</p>
              {/* <a href="servicespage" className="btn-learn-more mt-4">Read More</a> */}
              <div className="text-center">
                <Link to="/services/mobile-apps" className="btn-learn-more mt-4">
                  <button >
                    Read More
                  </button></Link>
              </div>
            </div>

          </Col>

          <Col xl={4} md={6} className="d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay={400}>
            <div className="icon-box">
              <div className="icon text-center  w-100"><div className="mainicon-div"><i><FontAwesomeIcon icon={faCartArrowDown} /></i></div></div>
              <h4 className="text-center"><Link to="/services/e-commerce">E-Commerce Development</Link ></h4>
              <p className="text-center">We provides enterprise Level E-commerce Store, E-commerce Website, E-commerce Shopping Website Design and Development.</p>
              {/* <a href="servicespage" className="btn-learn-more mt-4">Read More</a> */}
              <div className="text-center">
                <Link to="/services/e-commerce" className="btn-learn-more mt-4">
                  <button>
                    Read More
                  </button></Link>
              </div>
            </div>
          </Col>
          <Col xl={4} md={6} className="d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay={400}>
            <div className="icon-box">
              <div className="icon text-center w-100"><div className="mainicon-div"><i><FontAwesomeIcon icon={faDiamond} /></i></div></div>
              <h4 className="text-center "><Link to="/services/cms-website">CMS Based Website Development</Link></h4>
              <p className="text-center">We have Expertise in Website development using Wordpress, WIX, Squarespace, Magento CMS according to requirement.</p>
              {/* <a href="servicespage" className="btn-learn-more mt-4">Read More</a> */}
              <div className="text-center">
                <Link to="/services/cms-website" className="btn-learn-more mt-4">
                  <button >
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </Col>
          <Col xl={4} md={6} className="d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay={400}>
            <div className="icon-box ">
              <div className="icon text-center  w-100"><div className="mainicon-div"><i><FontAwesomeIcon icon={faCogs} /></i></div></div>
              <h4 className="text-center"><Link to="/services/web-maintenance">Web Maintenance & Customization</Link></h4>
              <p className="text-center">We have Expertise in all types of Websites Maintenance and Customization according to requirement. </p>
              <div className="text-center">
                <Link to="/services/web-maintenance" className="btn-learn-more mt-4">
                  <button >
                    Read More
                  </button></Link>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </section>
  );
}

export default React.memo(Services)
